import React from 'react'
import Image from './image'
// import MailingForm from './mailingForm'

const Body = () => (
  <div className="d-flex align-items-center flex-column justify-content-center h-100 w-100">
    {/* <h1 className="display-1 text-main">{('raw').toUpperCase()}</h1>
    <p><h6>{('capital').toUpperCase()}</h6></p> */}

    <div className="w-100" style={{ maxWidth: `300px` }}>
      <Image />
    </div>
  </div>
)

export default Body
